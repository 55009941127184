export const createItems = [
    {
        name: 'redirect_to',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Redirect To',
        placeholder: 'Add Redirect To',
        legend: 'Redirect To'
    },
    {
        name: 'category_id',
        component: 'category-select',
        style: 'width: 70%; margin-right: 10px',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Category',
        placeholder: 'Select Category',
        legend: 'Category'
    },
    {
        name: 'locale',
        component: 'select',
        style: 'width: calc(30% - 10px)',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Locale',
        placeholder: 'Select Locale',
        legend: 'Locale'
    },
    {
        name: 'post_meta_title',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Meta Title tag',
        placeholder: 'Meta Title tag',
        legend: '<Meta Title> tag'
    },
    {
        name: 'post_slug',
        component: 'slug',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'Slug',
        placeholder: 'Slug',
        legend: 'Slug'
    },
    {
        name: 'post_meta_description',
        component: 'textarea',
        previewComponent: 'preview-description-field',
        hideComponent: false,
        label: '<Meta Description> tag',
        placeholder: 'Meta Description tag',
        legend: '<Meta Description> tag'
    },
    {
        name: 'post_title',
        component: 'input',
        previewComponent: 'preview-text-field',
        hideComponent: false,
        label: 'H1 Title',
        placeholder: 'H1 Title',
        legend: 'H1 Title'
    },
    {
        name: 'post_content',
        component: 'content',
        previewComponent: 'preview-description-field',
        hideComponent: false,
        label: 'Content',
        placeholder: 'Content',
        legend: 'Content'
    }
]
