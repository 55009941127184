import {
    required,
    minLength
} from 'vuelidate/lib/validators';

export const validationBaseRules = {
    post_meta_title: {
        required,
        minLength: minLength(10)
    },
    post_meta_description: {
        required
    }
}
