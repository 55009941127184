import {
    required
} from 'vuelidate/lib/validators';

export const validationBaseRules = {
    redirect_to: {
        validationRedirect(value) {
            return new Promise((resolve) => {
                if (!value) {
                    resolve(true)
                    return
                }
                this.validateRedirect(value)
                    .then(() => {
                        resolve(true)
                    })
                    .catch(() => {
                        this.$v.form_data.redirect_to.$params.validationRedirect = false
                        resolve(false)
                    })
            })
        }
    },
    category_id: {
        required
    },
    locale: {
        required
    },
    cover_image_url: {
        // required
    }
}
