import {
    maxLength,
    minLength,
    required
} from 'vuelidate/lib/validators';

import { validationBaseRules } from '../revissionsRules'

export const validationRevisionRules = {
    form_data: {
        ...validationBaseRules,
        post_title_secondary: {
            // required
        },
        post_title: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(100)
        },
        post_content: {
            required,
            minLength: minLength(200)
        }
    }
}
