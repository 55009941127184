export const revisionItems = [
    {
        name: 'post_meta_title',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_meta_title',
        hideComponent: false,
        label: 'Meta Title tag',
        placeholder: 'Meta Title tag',
        legend: '<Meta Title> tag'
    },
    {
        name: 'post_meta_description',
        component: 'textarea',
        previewComponent: 'preview-description-field',
        preview_field: 'post_meta_description',
        hideComponent: false,
        label: '<Meta Description> tag',
        placeholder: 'Meta Description tag',
        legend: '<Meta Description> tag'
    },
    {
        name: 'post_title',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_title',
        hideComponent: false,
        label: 'H1 Title',
        placeholder: 'H1 Title',
        legend: 'H1 Title'
    },
    {
        name: 'post_content',
        component: 'content',
        previewComponent: 'preview-description-field',
        preview_field: 'post_content',
        hideComponent: false,
        label: 'Content',
        placeholder: 'Content',
        legend: 'Content'
    }
]
